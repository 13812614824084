module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tanach Study","short_name":"TS","description":"Tanach Study is a modern, web based platform for the study of the 24 books ofTanach","start_url":"/","background_color":"#009fc1","theme_color":"#009fc1","display":"minimal-ui","icon":"static/favicons/original-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5fd2b77e589d17e7775d353ac1cb7b5d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
